import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, graphql, navigate } from "gatsby";
import SettingIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import SEO from "../components/seo";
import Layout from "../components/layout";
import CounterCard from "../components/CounterCard";
import Page404 from "../components/404";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  cardGrid: {
    flexGrow: 1,
  },
  setting: {
    background: "#568536", //HGA green
    "&:hover": {
      background: "#385723", //darken HGA green
    },
    color: "#FFFFFF",
    position: "fixed",
    bottom: "23px",
    right: "80px",
  },
  settingIcon: {
    marginRight: 10,
  },
  start: {
    background: "#568536", //HGA green
    "&:hover": {
      background: "#385723", //darken HGA green
    },
    color: "#FFFFFF",
    position: "fixed",
    bottom: "20px",
    right: "80px",
  },
  home: {
    background: "#F50057", //HGA red
    "&:hover": {
      background: "#BA0948", //darken HGA red
    },
    color: "#FFFFFF",
    position: "fixed",
    bottom: "20px",
    right: "15px",
  },
  startIcon: {
    width: 30,
    height: 30,
  },
});

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  handleChangeTab = (event, newIndex) => {
    this.setState({ index: newIndex });
  };

  render() {
    const {
      data: { allStrapiCountertype },
      classes,
    } = this.props;
    const { index } = this.state;
    const selectedType = allStrapiCountertype.nodes[index].name;
    if (this.props.isLoggedIn) {
      return (
        <Layout color="#E7EAED">
          <SEO title="Settings" />
          <div className={classes.root}>
            <AppBar position="static" style={{ backgroundColor: "#568536" }}>
              <Tabs
                value={index}
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                onChange={this.handleChangeTab}
              >
                {allStrapiCountertype.nodes.map(type => (
                  <Tab key={type.id} component="div" label={type.name} />
                ))}
              </Tabs>
            </AppBar>
            <Grid
              container
              direction="row"
              spacing={2}
              style={{ padding: 15 }}
              justify="center"
            >
              <Grid item sm={10} md={4} lg={5}>
                <Paper square={true}>
                  <h3 style={{ padding: 17 }} align="center">
                    Detail Antrian
                  </h3>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="right">Jumlah Antrian</TableCell>
                        <TableCell align="left">8 (dummy)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Terakhir Dipanggil</TableCell>
                        <TableCell align="left">6 (dummy)</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Antrian Terlewat</TableCell>
                        <TableCell align="left">2, 3 (dummy)</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid item sm={10} md={8} lg={7}>
                <Grid container justify="center">
                  {allStrapiCountertype.nodes[index].counters.map(counter => (
                    <Grid item key={counter.id} style={{ margin: 10 }}>
                      <CounterCard
                        name={counter.name}
                        type={selectedType}
                        idLocket={counter.id}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            {/* </TabContainer> */}
          </div>
          <a
            href="https://q-sys.herokuapp.com/admin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Fab
              aria-label="Setting"
              variant="extended"
              className={classes.setting}
            >
              <SettingIcon className={classes.settingIcon} /> Ke Pengaturan
            </Fab>
          </a>
          <Link to="/">
            <Fab aria-label="Home" size="large" className={classes.home}>
              <HomeIcon />
            </Fab>
          </Link>
        </Layout>
      );
    }
    return <Page404 />;
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

//====================================================redux=======================================================
const mapStateToProps = state => {
  return {
    isLoggedIn: state.account.isLoggedIn,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(Settings));

export const pageQuery = graphql`
  query SettingsQuery {
    allStrapiCountertype {
      nodes {
        counters {
          id
          name
        }
        id
        name
      }
    }
  }
`;
