import React from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import VisibilityOn from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { setControlProps } from "../redux/globalActions";

const styles = {
  playIcon: {
    height: 30,
    width: 30,
  },
  card: {
    maxWidth: 345,
    display: "flex",
    background: "#E7EAED",
  },
};

class CounterCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  handleActivate = () => {
    this.setState({ isVisible: true });
  };

  handleDeactivate = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { classes, name, type } = this.props;
    const { isVisible } = this.state;
    console.log("counter selected type = " + type);
    return (
      <Card className={classes.card}>
        <Link to="/CounterControl/" style={{ textDecoration: "none" }}>
          <CardActionArea
            onClick={() =>
              this.props.setControlProps({ name: name, type: type })
            }
          >
            <CardContent>
              <h3 style={{ color: "#333333" }}>{name}</h3>
            </CardContent>
          </CardActionArea>
        </Link>
        <CardHeader
          action={
            <IconButton
              aria-label="more"
              //TODO: if deactivated, counter will not be displayed on 'Monitoring Antrian' Page
              onClick={isVisible ? this.handleDeactivate : this.handleActivate}
            >
              {isVisible ? (
                <VisibilityOff className={classes.playIcon} />
              ) : (
                <VisibilityOn className={classes.playIcon} />
              )}
            </IconButton>
          }
        />
      </Card>
    );
  }
}

CounterCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

//===================redux===================

const mapDispatchToProps = {
  setControlProps,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CounterCard));
